<script setup>
import PdfViewer from '../components/PdfViewer.vue'
import ExamAssigner from '../components/ExamAssigner.vue'
import { get_endpoint } from '../api.js';

import { useRouter } from 'vue-router';
</script>

<template>
  <h1 v-if="course">
    Assigning exams for {{project_id}}
  </h1>
  <h1 v-else>Loading...</h1>

  <button class="btn btn-primary" @click="goToProject">Go back to the project page</button>

  <div class="pdf_viewer_and_assigner">
    <div class="pdf"><PdfViewer :coids="coids" :coid="coid" :page="page" :project_id="project_id"/></div>
    <div class="students"><ExamAssigner :course="course" :project="project" :project_id="project_id" :split_exam_id="split_exam_id" @assigned-event="loadNext"/></div>
  </div>
</template>

<style scoped>
.pdf_viewer_and_assigner {
  display: flex;
  margin: 0 auto;
  width: 95%;
}

.pdf {
  flex: 70;
}

.students {
  flex: 30;
}
</style>

<script>
import Cookies from 'js-cookie';
import axios from 'axios';


export default {
  components: {
    ExamAssigner,
    PdfViewer,
  },
  methods: {
    goToProject() {
      this.router.push({path: '/project',
        query: { project_id: this.project_id}});
    },
    loadNext(assigned_exam_id, assigned_student_id) {
      this.project.split_exams[assigned_exam_id].assigned_student_id = assigned_student_id;
      console.log("Should load next");
      var next_exam_id = null;
      for (const [exam_id, exam] of Object.entries(this.project.split_exams)) {
        if (exam.assigned_student_id) {
          continue;
        }
        next_exam_id = exam_id;
        break;
      }
      if (!next_exam_id) {
        alert("No more exams");
        this.goToProject();
        return;
      }
      const next_exam = this.project.split_exams[next_exam_id];
      this.coid = next_exam.combined_original_id;
      this.page = next_exam.pages[0];
      this.router.push({path: '/assign',
        query: {
          project_id: this.project_id,
          split_exam_id: next_exam_id
        }});
    },
  },
  computed: {
    project_id() {
      return this.$route.query.project_id;
    },
    split_exam_id() {
      return this.$route.query.split_exam_id;
    },
  },
  data() {
    return {
      project: null,
      course: null,
      session: null,

      coids: null,
      coid: null,
      page: null,
    };
  },
  mounted() {
    this.router = useRouter();
    this.session = Cookies.get('session');
    if (!this.session) {
      console.log('Cookie not found');
    }

    var postData = {
      session: this.session,
      id: this.project_id,
    };

    axios.post(get_endpoint() + '/api/project/get', postData)
      .then(response => {
        this.project = response.data.body;
        console.log("Loaded project.");

        var coids = []
        for (const [coid, ] of Object.entries(this.project.combined_originals)) {
          coids.push(coid);
        }
        this.coids = coids;

        this.coid = this.project.split_exams[this.split_exam_id].combined_original_id;
        this.page = this.project.split_exams[this.split_exam_id].pages[0];

        postData = {
          session: this.session,
          id: this.project.course_id,
        };
        axios.post(get_endpoint() + '/api/course/get', postData)
          .then(response => {
            this.course = response.data.body;
            console.log("Loaded course");
          })
          .catch(error => {
            this.error = 'An error occurred: ' + error.message;
          });
      })
      .catch(error => {
        this.error = 'An error occurred: ' + error.message;
      });
  },
}
</script>
