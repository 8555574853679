<script setup>
import ProjectList from './ProjectList.vue'
</script>

<template>
  <div v-if="course_list">
    <div v-for="[course_id, course_name] of courses" :key="course_id">
      <h3>{{course_name}}</h3>
      <ProjectList :session="session" :course_id="course_id"/>
    </div>
  </div>
  <p v-else>Loading...</p>
</template>

<script>
import axios from 'axios';
import { get_endpoint } from '../api.js';

export default {
  props: {
    session: String,
  },
  data() {
    return {
      course_list: null,
    };
  },
  computed: {
    courses() {
      if (!this.course_list) return null;
      return Object.entries(this.course_list);
    },
  },
  mounted() {
    if (!this.session) {
      console.log("no session");
      return;
    }
    const postData = {
      session: this.session,
    };

    axios.post(get_endpoint() + '/api/course/list', postData)
      .then(response => {
        this.course_list = response.data.body;
      })
      .catch(error => {
       console.log('An error occurred: ' + error.message);
      });
  }
};
</script>

<style scoped>
.akak {
  background-color: #eaedef;
}
</style>
