<template>
  <div class="container">
    <h2>Split exams</h2>
    <div v-if="project && project.split_exams && Object.entries(project.combined_originals).length > 0">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Combined Original file</th>
            <th scope="col">Pages</th>
            <th scope="col">Number of pages</th>
            <th scope="col">Assigned student</th>
          </tr>
        </thead>
        <tbody class="table-group-divider" style="border-top-color: #909090">
          <tr v-for="(exam,id) in project.split_exams" :key="id">
            <td>{{id}}</td>
            <td>
              {{project.combined_originals[exam.combined_original_id].original_name}}
            </td>
            <td>
              {{exam.pages[0]}} - {{exam.pages.at(-1)}}
            </td>
            <td>
              {{exam.pages.at(-1) - exam.pages[0] + 1}}
            </td>
            <td v-if="course && course.students && exam.assigned_student_id">
              <a :href="`assign?project_id=${project_id}&split_exam_id=${id}`">{{course.students[exam.assigned_student_id].full_name}}</a>
            </td>
            <td v-else><a style="color: #f00" :href="`assign?project_id=${project_id}&split_exam_id=${id}`">Unassigned</a></td>
          </tr>
        </tbody>
      </table>
      <div v-if="this.problems" class="alert alert-danger" role="alert"><h1>Problems</h1>
        <div v-for="problem in this.problems" :key="problem" class="problem_container">
          <div class="spinner-grow text-danger" role="status" ></div><div><strong>{{problem}}</strong></div>
        </div>
      </div>
      <h3>Summary</h3>
      <table class="table">
        <thead>
          <tr>
            <th>Total number of exams</th>
            <th>Total number of pages</th>
            <th>Students assigned an exam</th>
            <th>Students without an assigned exam</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{total_split_exams}}</td>
            <td>{{total_page_count}}</td>
            <td>{{assigned_students}}</td>
            <td>{{unassigned_students}}</td>
          </tr>
        </tbody>
      </table>
    </div>
      <button class="btn btn-primary" @click="download_split">Download split exams</button> <br /> <br />
  </div>
</template>

<script>
import axios from 'axios';
import { get_endpoint } from '../api.js';

  export default {
    emits: ['project-updated'],
    props: {
      session: String,
      project: Object,
      project_id: String,
    },
    data() {
      return {
        course: null,
        total_split_exams: null,
        total_page_count: null,
        assigned_students: null,
        unassigned_students: null,
        problems: null,
      }
    },
    methods: {
      async download_split() {
        const url = get_endpoint() + '/api/project/download_split';
        const payload = {
          session: this.session,
          id: this.project_id
        };

        try {
          const response = await axios({
            method: 'post',
            url: url,
            data: payload,
            responseType: 'blob'  // Important for handling binary data like files
          });

          // Handle creating a download link
          this.downloadFile(response.data);
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      },
      downloadFile(data) {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([data]));

        // Create a temporary anchor tag to trigger download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'split_exams.zip');  // Name the download file

        // Append to the document
        document.body.appendChild(link);

        // Trigger the download by simulating a click
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        // Release the URL object
        window.URL.revokeObjectURL(url);
      },
    },
    watch: {
      project: {
        immediate: true,
        handler() {
          if (!this.project) {
            return;
          }
          const postData = {
            session: this.session,
            id: this.project.course_id,
          };
          axios.post(get_endpoint() + '/api/course/get', postData)
            .then(response => {
              this.course = response.data.body;

              var split_exam_count = 0;
              var total_page_count = 0;
              var assigned_students = 0;
              for (const se of Object.values(this.project.split_exams)) {
                split_exam_count++;
                total_page_count += se.pages.length;
                if (se.assigned_student_id) {
                  assigned_students++;
                }
              }
              this.total_split_exams = split_exam_count;
              this.total_page_count = total_page_count;
              this.assigned_students = assigned_students;
              this.unassigned_students = Object.keys(this.course.students).length - assigned_students;

              var total_page_count_co = 0;
              for (const co of Object.values(this.project.combined_originals)) {
                total_page_count_co += co.total_page_count;
              }
              if (total_page_count_co !== total_page_count) {
                this.problems = ["Total page count of split exams and combined originals does not match"];
              } else {
                this.problems = null;
              }
            })
            .catch(error => {
              this.error = 'An error occurred: ' + error.message;
            });

        },
      }
    },
  };
</script>

<style scoped>
.container {
  padding: 10px;
  background-color: #eeeeee;
}

.problem_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>
