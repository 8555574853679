<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Large Exam Collider</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="/">Home</a>
          </li>
        </ul>
        <div v-if="session">
          <button class="btn btn-outline-primary" style="margin-right: 10px;" @click="settings">Settings</button>
          <button class="btn btn-outline-primary" @click="logout">Logout</button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: 'NavBar',
  props: {
    session: String,
  },
  emits: ['session-updated'],
  methods: {
    logout() {
      Cookies.remove("session");

      this.$emit('session-updated');

    },
    settings() {
      this.$router.push('/settings');
    }
  },
}
</script>

<style>
</style>
