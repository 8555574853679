<script setup>
import { watch, defineProps, reactive, toRaw } from 'vue';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import "pdfjs-dist/web/pdf_viewer.css";
import { get_endpoint } from '../api.js';

import Cookies from 'js-cookie';

const props = defineProps({
  coids: Array,
  coid: String,
  page: Number,
  project_id: String,
});

pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.1.392/pdf.worker.mjs";

const pdfs = reactive({
  pdfs: null,
  pdfs_loaded: 0,
});

const checkLoaded = async () => {
  if (!(props.coids && props.coid && props.page && props.project_id)) {
    return;
  }
  if (!pdfs.pdfs) {
    pdfs.pdfs = await PreloadPdfs(props, pdfs);
  }

  const pdf = toRaw(pdfs.pdfs.get(props.coid));
  console.log(pdf);
  const page = await pdf.getPage(props.page);
  const scale = 1.25;

  var viewport = page.getViewport({ scale: scale, });
  var outputScale = window.devicePixelRatio || 1;

  var canvas = document.getElementById('pdfCanvas');
  if (!canvas) return;
  var context = canvas.getContext('2d');
  if (!context) return;

  canvas.width = Math.floor(viewport.width * outputScale);
  canvas.height = Math.floor(viewport.height * outputScale);
  canvas.style.width = Math.floor(viewport.width) + "px";
  canvas.style.height =  Math.floor(viewport.height) + "px";

  var transform = outputScale !== 1
    ? [outputScale, 0, 0, outputScale, 0, 0]
    : null;

  var renderContext = {
    canvasContext: context,
    transform: transform,
    viewport: viewport
  };
  page.render(renderContext);
};

watch([() => props.coids,
       () => props.coid,
       () => props.page,
       () => props.project_id,
       ], () => {
         checkLoaded();
       }, {
         immediate: true
       });
</script>

<template>
  <h3 v-if="!pdfs.pdfs && coids">Loading PDFs... loaded {{pdfs.pdfs_loaded}} out of {{coids.length}}</h3>
  <div>
    <canvas id="pdfCanvas"></canvas>
  </div>
</template>

<script>
import axios from 'axios';

const session = Cookies.get('session');
if (!session) {
  console.log('Cookie not found');
}

async function GetCombinedOriginal(project_id, co_id, pdfs_reactive) {
  try {
    const response = await axios.post(get_endpoint() + '/api/project/get_combined_original', {
      session: session,
      project_id: project_id,
      co_id: co_id
    },
      {
        responseType: 'arraybuffer',
      });
    console.log("Finished downloading COID ", co_id, " from project ", project_id, ". Bytes = ",
      response.data.byteLength);
    pdfs_reactive.pdfs_loaded++;
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

async function PreloadPdfs(props, pdfs_reactive) {
  console.log("Preloading PDFs..");

  var preloaded_pdfs_promises = new Map();

  for (const coid of props.coids) {
    preloaded_pdfs_promises.set(coid, GetCombinedOriginal(props.project_id, coid, pdfs_reactive));
  }

  var preloaded_pdfs = new Map();
  for (const [coid, promise] of preloaded_pdfs_promises) {
    const pdf_data = await promise;
    const doc = await pdfjsLib.getDocument({data: pdf_data}).promise;
    preloaded_pdfs.set(coid, doc);
  }
  console.log("Loaded all PDFs");
  return preloaded_pdfs;
}

export default {
  data() {
    return {
      pdfs: null,
    };
  },
};
</script>
