<template>
  <!-- <a v-if="project.course_id == course_id" :href="`project?project_id=${id}`">{{project.name}}</a> -->
  <!-- Projects for {{course_id}} and session {{session}}: {{state.projects}} -->
    <p v-for="[project_id, project_name] of state.projects" :key="project_id" ><a :href="`project?project_id=${project_id}`">{{project_name}}</a></p>
</template>

<script setup>

import { onMounted, defineProps, reactive } from 'vue';
import axios from 'axios';
import { get_endpoint } from '../api.js';

const props = defineProps({
  session: String,
  course_id: String,
});

const state = reactive({
  projects: [],
});

onMounted(() => {
  if (!props.session || !props.course_id) {
    return;
  }

  state.projects.push('updated')

  const postData = {
    session: props.session,
    id: props.course_id,
  };

  state.projects = [];
  axios.post(get_endpoint() + '/api/course/project_list', postData)
    .then(response => {
      state.projects = Object.entries(response.data.body);
    })
    .catch(error => {
     console.log('An error occurred: ' + error.message);
    });
});

</script>

<style scoped>
.akak {
  background-color: #eaedef;
}
</style>
