<script setup>
import OriginalExamList from '../components/OriginalExamList.vue'
import ExamSplitter from '../components/ExamSplitter.vue'
import SplitExams from '../components/SplitExams.vue'
</script>

<template>
  <h1 id="project_name_heading"></h1>

  <div class="project_tools">
    <OriginalExamList :project_id="project_id" :project="project" :session="session" @project-updated="update_project"/>
    <div v-if="analyzed">
    <ExamSplitter :project_id="project_id" :project="project" :session="session" @project-updated="update_project" />
    <SplitExams :project_id="project_id" :project="project" :session="session" @project-updated="update_project" />
    </div>
  </div>

</template>

<script>
import Cookies from 'js-cookie';
import axios from 'axios';
import { get_endpoint } from '../api.js';

  export default {
    computed: {
      project_id() {
        return this.$route.query.project_id;
      }
    },
    data() {
      return {
        project: null,
        session: null,
        analyzed: false,
      };
    },
    methods: {
      check_analyzed() {
        if (!this.project) {
          console.log("No project");
          return false;
        }
        if (!this.project.combined_originals) {
          console.log("No combined originals");
          return false;
        }
        var analyzed = false;
        for (const [coid,co] of Object.entries(this.project.combined_originals)) {
          if (!co.pages) {
            console.log("No pages for ", coid);
            return false;
          }
          analyzed = true;
        }
        return analyzed;
      },
      update_project() {
        const postData = {
          session: this.session,
          id: this.project_id,
        };

        axios.post(get_endpoint() + '/api/project/get', postData)
          .then(response => {
            this.project = response.data.body;
            this.analyzed = this.check_analyzed();
          })
          .catch(error => {
            this.error = 'An error occurred: ' + error.message;
          });
      },
    },
    mounted() {
      this.session = Cookies.get('session');
      if (!this.session) {
        console.log('Cookie not found');
      }

      this.update_project();
    },
  }
</script>

<style scoped>
.project_tools {
  display: flex;
  margin: 0 auto;
  width: 70%;
  flex-direction: column;
  align-items: center;
}
</style>
