<script setup>
import CourseList from '../components/CourseList.vue'
import axios from 'axios';
</script>

<template>
  <img src="../assets/logo.png" width="10%">
  <div>
    <h1>Hola {{user}}!</h1>

    <div class="courses_and_projects">
      <div class="column">
        <CourseList v-if="user" :session="session"/>
      </div>
    </div>
  </div>
</template>

<script>
  import Cookies from 'js-cookie';
  import { get_endpoint } from '../api.js';

  export default {
    name: 'MainPage',
    data () {
      return {
        user: null,
        session: null,
        error: null,
      };
    },
    mounted() {
      this.session = Cookies.get('session');
      if (!this.session) {
        console.log('Cookie not found');
      }

      const postData = {
        session: this.session
      };

      axios.post(get_endpoint() + '/api/check_session', postData)
        .then(response => {
          if (response.data.status != "Ok") {
            this.$router.push('/login');
          }
          this.user = response.data.body;

        })
        .catch(error => {
          console.log(error);
          this.error = 'No user?';
        });

      return;
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.courses_and_projects {
  display: flex;
  margin: 0 auto;
  width: 70%;
}

.column {
  flex: 1;
  margin: 10px;
}

</style>
