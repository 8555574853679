<template>
  <div class="login-container">
    <h1>Login</h1>
    <form class="login-form" @submit.prevent="handleLogin">
      <div class="mb-3 field-container">
        <label for="user" class="form-label">Username:</label>
        <input type="text" id="user" class="form-control" v-model="loginForm.user" required>
      </div>
      <div class="mb-3 field-container">
        <label for="password" class="form-label">Password:</label>
        <input type="password" id="password" class="form-control" v-model="loginForm.password" required>
      </div>
      <button class="btn btn-primary" type="submit">Login</button>
    </form>
  </div>
</template>

<script>
  import axios from 'axios';
  import Cookies from 'js-cookie';
  import { get_endpoint } from '../api.js';
  export default {
    name: 'LoginView',
    emits: ['session-updated'],
    data() {
      return {
        loginForm: {
          user: '',
          password: '',
        },
      };
    },
    methods: {
      async handleLogin() {
        try {
          const response = await axios.post(get_endpoint() + 'api/check_login', this.loginForm);
          if (response.data.status != "Ok") {
            console.log("Invalid username/password");
            return;
          }
          Cookies.set('session', response.data.body);

          this.$emit('session-updated');
          this.$router.push('/');

        } catch (error) {
          console.log(error);
        }
      },
    },
  };
</script>

<style>
.login-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.field-container {
  display: flex;
  align-items: center;
}

.form-label {
  margin-right: 10px;
  margin-bottom: 0;
  width: 120px;
  text-align: left;
}
</style>
