<template>
  <div v-if="props.course && props.project && props.split_exam_id && props.project.split_exams[props.split_exam_id].assigned_student_id">
    <h2 style="border: 1px solid black;">
      Exam assigned to:
      <h3>
        {{props.course.students[props.project.split_exams[props.split_exam_id].assigned_student_id].full_name}}
      </h3>
    </h2>
  </div>
  <h3>Students without an assigned exam</h3>
  <table v-if="course" class="table">
    <thead>
      <tr>
        <th scope="col">Student</th>
        <th scope="col">Confidence</th>
      </tr>
    </thead>
    <tbody v-if="students.unassigned" class="table-group-divider" style="border-top-color: #909090">
      <tr v-for="student in students.unassigned" :key="student.id" >
        <td class="name-cell" @mouseover="hoveredStudentId = student.id" @mouseout="hoveredStudentId = null">
          <button @click="assignTo(student.id)" class="btn btn-primary assign-button" :class="{'invisible': hoveredStudentId !== student.id}">
            Assign to {{student.full_name}}
          </button>
          <span class="name-span" :class="{'invisible': hoveredStudentId === student.id}">
            {{student.full_name}}
          </span>
        </td>
        <td>{{student.confidence}}%</td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { defineEmits, defineProps, watch, reactive, ref } from 'vue';
import Cookies from 'js-cookie';
import axios from 'axios';
import { get_endpoint } from '../api.js';

const hoveredStudentId = ref(null);

const props = defineProps({
  course: Object,
  project: Object,
  project_id: String,
  split_exam_id: String,
});

const session = Cookies.get('session');
if (!session) {
  console.log('Cookie not found');
}

const emit = defineEmits(['assigned-event']);

function assignTo(student_id) {
  console.log("Assigning exam ", props.split_exam_id, " to ", student_id);
  const postData = {
    session: session,
    project_id: props.project_id,
    exam_id: props.split_exam_id,
    student_id: student_id,
  };
  axios.post(get_endpoint() + '/api/project/assign', postData)
    .then(() => {
    })
    .catch(error => {
      this.error = 'An error occurred: ' + error.message;
    });
  emit('assigned-event', props.split_exam_id, student_id);
}

const students = reactive({
  unassigned: null,
});

var first_page_counts = null;

function populate() {
  if (!first_page_counts) {
    first_page_counts = new Map();

    for (const [,exam] of Object.entries(props.project.split_exams)) {
      const first_page = exam.pages[0];
      const coid = exam.combined_original_id;
      const words = props.project.combined_originals[coid].pages[first_page].split(' ');
      for (const word of words) {
        const count = first_page_counts.get(word);
        if (count) {
          first_page_counts.set(word, count + 1);
        } else {
          first_page_counts.set(word, 1);
        }
      }
    }
  }
  var assigned_students = new Set();
  for (const [,exam] of Object.entries(props.project.split_exams)) {
    if (exam.assigned_student_id) {
      assigned_students.add(exam.assigned_student_id);
    }
  }

  var unassigned = [];
  for (const [id,student] of Object.entries(props.course.students)) {
    if (!assigned_students.has(id)) {
      unassigned.push(student);
    }
  }

  const split_exam = props.project.split_exams[props.split_exam_id];
  const coid = split_exam.combined_original_id;
  const main_page_number = split_exam.pages[0];

  const all_words = props.project.combined_originals[coid].pages[main_page_number].split(' ');

  let important_words = [];
  const num_split_exams = Object.values(props.project.split_exams).length;
  for (const word of all_words) {
    const count = first_page_counts.get(word);
    if (!count) {
      console.log("Couldn't find word in first page counts? WTF: ", word);
      return;
    }
    if (count < num_split_exams / 10) {
      important_words.push(word);
    }
  }
  console.log("Important Words =",important_words);

  for (let student of unassigned) {
    student.confidence = edit_distance('asdf', student.full_name);

    const split_name = student.full_name
      .toLowerCase()
      .replaceAll('á', 'a')
      .replaceAll('é', 'e')
      .replaceAll('í', 'i')
      .replaceAll('ó', 'o')
      .replaceAll('ú', 'u')
      .replaceAll('ñ', 'n')
      .replaceAll(',', '')
      .split(' ')
      .filter(w => w.length > 0);

    let part_scores = new Map()
    for (const part of split_name) {
      part_scores.set(part, 0.0);
    }

    for (const part of split_name) {
      for (const word of important_words) {
        let prev_score = part_scores.get(part);
        if (!prev_score) prev_score = 0.0;
        const dist = edit_distance(word, part);

        if (dist == 0) {
          part_scores.set(part, 2);
        } else {
          if (prev_score < 1.0 / dist) {
            part_scores.set(part, 1.0 / dist);
          }
        }
      }
    }
    let total_score = 0;
    for (const [, score] of part_scores) {
      total_score += score;
    }
    let score = total_score / split_name.length/2;
    student.confidence = Math.round(score * 10000)/100;
  }


  unassigned.sort((a,b) => {
    if (a.confidence > b.confidence) {
      return -1;
    }

    if (a.confidence < b.confidence) {
      return 1;
    }

    return 0;
  });

  students.unassigned = unassigned;
}

function edit_distance(str1, str2) {
  const track = Array(str2.length + 1).fill(null).map(() =>
    Array(str1.length + 1).fill(null));
  for (let i = 0; i <= str1.length; i += 1) {
    track[0][i] = i;
  }
  for (let j = 0; j <= str2.length; j += 1) {
    track[j][0] = j;
  }
  for (let j = 1; j <= str2.length; j += 1) {
    for (let i = 1; i <= str1.length; i += 1) {
      const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
      track[j][i] = Math.min(
        track[j][i - 1] + 1,
        track[j - 1][i] + 1,
        track[j - 1][i - 1] + indicator,
      );
    }
  }
  return track[str2.length][str1.length];
}

watch([
    () => props.split_exam_id,
    () => props.course,
    () => props.project,
    () => props.project_id,
  ],
  () => {
  if (props.split_exam_id && props.course && props.project && props.project_id) {
    populate();
  }
  }, {
  immediate: true
});

</script>

<style>
.name-cell {
  position: relative;
  height: 100%; /* Ensure the cell has full height as needed */
  min-width: 350px;
}

.assign-button, .name-span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.invisible {
  visibility: hidden;
}
</style>
