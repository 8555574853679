<template>
  <div class="container">
    <h2>Original exams</h2>

    <div id="combined-originals-file-list"></div>

    <div v-if="project" class="mb-3">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">File name</th>
            <th scope="col">Pages</th>
            <th scope="col">Analyzed</th>
          </tr>
        </thead>
        <tbody class="table-group-divider" style="border-top-color: #909090">
          <tr v-for="co in project.combined_originals" :key="co.id">
            <th scope="row">{{co.original_name}}</th>
            <td>{{co.total_page_count}}</td>
            <td>
              <span v-if="!is_analyzing && co.pages" class="badge text-bg-success">Analyzed</span>
              <span v-else-if="is_analyzing" class="badge text-bg-info">Analyzing...
              </span>
              <span v-else class="badge text-bg-warning">Not analyzed</span>
            </td>
          </tr>
        </tbody>
        <tfoot class="table-group-divider" style="border-top-color: #909090;">
          <tr>
            <th scope="row">Total</th>
            <td><strong>{{total_page_count}}</strong></td>
            <td></td>
          </tr>
        </tfoot>
      </table>
      <form @submit.prevent="handleUpload">
        <input id="file" type="file" name="file" style="visibility:hidden; display:none;" multiple>
        <div style="display: flex">
          <label for="file" class="btn btn-primary">Select files</label>
          <button v-if="!is_uploading" class="btn btn-primary" id="upload_button" style="margin-left: 50px;">Upload selected files</button>
          <button v-else class="btn btn-secondary" style="margin-left: 10px; align-itmes: center;" disabled>
            <div style="align-items: center; display:flex;">
              Uploading...
              <div class="spinner-border text-info" style="margin-left: 5px;" role="status"></div>
            </div>
          </button>
        </div>
      </form>
    </div>
    <button v-if="!is_resetting" class="btn btn-danger" @click="reset_project" style="margin-right: 10px;">Reset</button>
    <button v-else class="btn btn-secondary" style="margin-left: 10px; align-itmes: center;" disabled>
      <div style="align-items: center; display:flex;">
        Resetting...
        <div class="spinner-border text-info" style="margin-left: 5px;" role="status"></div>
      </div>
  </button>


    <button v-if="!is_analyzing" class="btn btn-primary" @click="analyze" style="margin-left: 10px;">
      Analyze
    </button>
    <button v-else class="btn btn-secondary" style="margin-left: 10px; align-itmes: center;" disabled>
      <div style="align-items: center; display:flex;">
        Analyzing...
        <div class="spinner-border text-info" style="margin-left: 5px;" role="status"></div>
      </div>
  </button>
  </div>
</template>

<script>
  import axios from 'axios';
  import { get_endpoint } from '../api.js';

  async function UploadFiles(session, project_id, type, files) {
    console.log(session, project_id, name, files);

    const formData = new FormData();

    for (const file of files) {
      formData.append('files[]', file);
    }

    formData.append('session', session);
    formData.append('project_id', project_id);
    formData.append('type', type);

    try {
      const response = await axios.post(get_endpoint() + '/api/upload_file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Files uploaded successfully:', response);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  }

  export default {
    props: {
      session: String,
      project: Object,
      project_id: String,
    },
    emits: ['project-updated'],
    data() {
      return {
        total_page_count: null,
        is_analyzing: false,
        is_uploading: false,
        is_resetting: false,
      };
    },
    methods: {
      async handleUpload() {
        this.is_uploading = true;
        const input = document.getElementById("file");
        if (input == null) return;
        console.log(input.files);

        await UploadFiles(this.session, this.project_id, "combined_originals", input.files);
        this.is_uploading = false;
        this.$emit('project-updated');
      },
      reset_project() {
        this.is_resetting = true;
        const postData = {
          session: this.session,
          id: this.project_id,
        };

        axios.post(get_endpoint() + '/api/project/reset', postData)
          .then(() => {
            // TODO: Reload
            this.$emit('project-updated');
            this.is_resetting = false;
          })
          .catch(error => {
            this.error = 'An error occurred: ' + error.message;
            this.is_resetting = false;
          });
      },
      analyze() {
        this.is_analyzing = true;
        const postData = {
          session: this.session,
          id: this.project_id,
        };

        axios.post(get_endpoint() + '/api/project/analyze', postData)
          .then(() => {
            this.is_analyzing = false;
            this.$emit('project-updated');
          })
          .catch(error => {
            this.error = 'An error occurred: ' + error.message;
            this.is_analyzing = false;
          });
      },
    },
    watch: {
      project: {
        immediate: true,
        handler() {
          if (!this.project) return;
          var count = 0;
          for (const co of Object.values(this.project.combined_originals)) {
            count += co.total_page_count;
          }
          this.total_page_count = count;
        },
      },
    },
  };
</script>

<style scoped>
.container {
  padding: 10px;
  background-color: #eeeeee;
  margin-bottom: 10px;
}
</style>
