<template>
  <div class="container">
    <h1>Settings</h1>
    <form @submit.prevent="handle_settings">
      <div class="mb-3 field-container">
        <label for="password" class="form-label">Update password:</label>
        <input type="password" id="password" class="form-control" v-model="formData.password" required>
      </div>
      <button class="btn btn-primary" @click="update_settings">Update</button>
    </form>
  </div>
</template>

<script>
  import axios from 'axios';
  import Cookies from 'js-cookie';
  import { get_endpoint } from '../api.js';

  export default {
    name: 'SettingsView',
    data() {
      return {
        formData: {
          password: null,
        },
      };
    },
    methods: {
      update_settings() {
        const postData = {
          session: Cookies.get('session'),
          password: this.formData.password,
        };

        axios.post(get_endpoint() + '/api/update_password', postData)
          .then(() => {
            alert("Settings updated");
          })
          .catch(error => {
            console.log('An error occurred: ' + error.message);
          });
      },
    },
  };
</script>

<style>

.form-label {
  margin-right: 10px;
  margin-bottom: 0;
  width: 220px;
  text-align: left;
}

</style>
