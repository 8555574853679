<template>
  <NavBar :session="session" @session-updated="session_updated()" />
  <router-view v-if="session"/>
  <LoginView v-else  @session-updated="session_updated()" />
</template>

<script>
import NavBar from './components/NavBar.vue'
import LoginView from './views/LoginView.vue'
import Cookies from 'js-cookie';

export default {
  name: 'App',
  data() {
    return {
      session: null,
    };
  },
  components: {
    NavBar,
    LoginView,
  },
  methods: {
    session_updated() {
      this.session = Cookies.get('session');
    },
  },
  mounted() {
    this.session = Cookies.get('session');
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
