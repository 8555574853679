<template>
  <div class="container">
    <h2>Exam splitter</h2>
    <label>Each main page must have all of these words:</label>
    <input v-model="split_words_string" style="margin-right: 10px; margin-left: 3px;">
    <button @click="split" style="margin-left: 10px; margin-right: 3px;" class="btn btn-primary" id="split_button">Split</button>
    <label>(Case-insensitive)</label>
    <div id="split_pages_list"></div>
  </div>
</template>

<script>
import axios from 'axios';
import { get_endpoint } from '../api.js';

  export default {
    emits: ['project-updated'],
    props: {
      session: String,
      project_id: String,
      project: Object,
    },
    data() {
      return {
        split_words_string: 'examen parcial probabilidad curso documento 2023 2024',
      };
    },
    methods: {
      split() {
        const words = this.split_words_string.split(' ');
        console.log("Splitting by ", words);

        console.log("PROJECT = ", this.project);

        const postData = {
          session: this.session,
          id: this.project_id,
          words: words,
        };

        axios.post(get_endpoint() + '/api/project/split', postData)
          .then(() => {
            this.$emit('project-updated');
          })
          .catch(error => {
            this.error = 'An error occurred: ' + error.message;
          });
      },
    },
  };
</script>

<style scoped>
.container {
  padding: 10px;
  background-color: #eeeeee;
}
</style>
